<template>
    <div class="sb">
        <nav-bar headerTitle="超鹿健康权益礼包" ref="head" :showBack="true" :headerBack="newAppBack"></nav-bar>
        <img src="https://img.chaolu.com.cn/ACT/get-mini-voucher/jinping/01.jpg" alt="">

        <div class="poster row-center-center" :class="{gray: detail.receiveStatus === 'FINISHED'}" @click="getVoucher">
            {{ buttonStatus }}
        </div>

<!--        <img src="https://img.chaolu.com.cn/ACT/get-mini-voucher/jinping/02.jpg" alt="">-->

        <img src="https://img.chaolu.com.cn/ACT/get-mini-voucher/jinping/03.jpg" alt="">
        <img src="https://img.chaolu.com.cn/ACT/get-mini-voucher/jinping/04.jpg" alt="">
        <img src="https://img.chaolu.com.cn/ACT/get-mini-voucher/jinping/05.jpg" alt="">
        <img src="https://img.chaolu.com.cn/ACT/get-mini-voucher/jinping/06.jpg" alt="">

<!--        <div class="info col-start-center">-->
<!--            <img src="https://img.chaolu.com.cn/ACT/activity-lottery-202307/1.png" alt="">-->
<!--        </div>-->

        <van-popup v-model="isGetShow" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false">
            <div v-if="isSuccess" class="confirm-box col-start-center">
                <img v-for="(v, k) in userRecords" :key="k" :src="v.imgUrl" alt="">
                <div @click="appointment" class="button f36 fw6 row-center-center">前往约课</div>
            </div>

            <div v-else class="confirm-box got col-start-center" :class="{noNew: detail.receiveStatus === 'NEW_USER_OF_NON_MICRO_PER_COURSE'}">
                <p class="t1 f36 fw6">{{ receiveStatus }}</p>
                <div @click="appointment" class="button f36 fw6 row-center-center">前往约课</div>
            </div>

            <img @click="isGetShow = false" class="result-close"
                 src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png"
                 alt="">
        </van-popup>
    </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import userMixin from '@/mixin/userMixin'
import {getParam} from '@/lib/utils'
import navBar from '@/components/nav-bar-202210/index'
import {newAppBack, goMyVoucher, gotoAppPage} from '@/lib/appMethod'

export default {
    mixins: [userMixin],
    components: {
        navBar
    },
    data() {
        return {
            isGetShow: false,
            detail: {},
            isSuccess: false,
            coachId: '', // teacherId会与mixin中重叠
            userRecords: [],
            isNotNew: false,
        }
    },
    computed: {
        receiveStatus() {
            const s = this.detail.receiveStatus
            if (s === 'RECEIVED') return '您已领过券了，不可重复领取'
            if (s === 'NEW_USER_OF_NON_MICRO_PER_COURSE') return '您已不是私教课及小班课新用户，暂无可领取优惠券'
            return '您暂无可领取优惠券'
        },

        buttonStatus() {
            const s = this.detail.receiveStatus
            if (s === 'RECEIVED') return '已领取'
            if (s === 'HAS_GONE') return '已抢光'
            if (s === 'FINISHED') return '已结束'
            if (s === 'NEW_USER_OF_NON_MICRO_PER_COURSE ') return '暂不可领取'
            return '一键领取'
        }

    },
    async created() {
        await this.$getAllInfo()
        this.coachId = getParam().teacherId
        this.getInfo()
    },
    methods: {
        newAppBack,
        getInfo() {
            this.$axios.post(this.baseURLApp + "/activityStrategyConfig/getActivityInfo", {
                userId: this.userId,
                token: this.token,
                code: 'GOLD_ACTIVITY'
            }).then((res) => {
                this.detail = res.data
                this.skip = res.data.skip
                if ( !['FINISHED', 'CAN_RECEIVE'].includes(this.detail.receiveStatus) ) {
                    this.isGetShow = true
                }
            })

        },
        getVoucher() {
            if (this.detail.receiveStatus === 'CAN_RECEIVE') {
                this.$toast.loading()
                this.$axios.post(this.baseURLApp + "/activityStrategyConfig/acceptAwardByConfig", {
                    userId: this.userId,
                    token: this.token,
                    teacherId: this.coachId,
                    code: 'GOLD_ACTIVITY'
                }).then((res) => {
                    if(res.data.status === 0) {
                        this.isSuccess = true
                    }else if(res.data.status === 1){
                        this.detail.receiveStatus = 'RECEIVED'
                        this.isSuccess = false
                    }else{
                        this.detail.receiveStatus = 'NEW_USERS_OF_NON_MICRO_COURSE'
                        this.isSuccess = false
                    }
                    this.skip = res.data.skip
                    this.userRecords = res.data.userRecords || []
                    this.isGetShow = true
                    this.$toast.clear()
                    this.getInfo()
                }).catch((res) => {
                    this.getInfo()
                })
            } else {
                this.getInfo()
            }
        },
        async appointment() {
            if(this.skip === 1){
                gotoAppPage(9, '')
            }else{
                gotoAppPage(1, '')
            }
            return false
        }
    },
}
</script>

<style lang="less" scoped>
.sb {
    width: 100%;
    min-height: 100vh;
    &>img{
        width: 100%;
        display: block;
    }
    * {
        box-sizing: border-box;
    }

    .poster {
        width: 750px;
        height: 130px;
        background-size: 100%;
        background-image: url(https://img.chaolu.com.cn/ACT/get-mini-voucher/jinping/02.jpg);
        font-weight: bold;
        font-size: 34px;
        padding-bottom: 10px;
        box-sizing: border-box;
        &.gray{
            filter: grayscale(100%);
        }
    }

    .info {
        width: 686px;
        margin: 34px auto;
        img{
            width: 100%;
            display: block;
        }
    }

    .confirm-box {
        width: 566px;
        height: 710px;
        background-size: 100%;
        margin: 0 auto;
        background-image: url(https://img.chaolu.com.cn/ACT/get-mini-voucher/jinping/7.png);
        padding: 166px 0 0;
        &>img{
            width: 492px;
            height: 150px;
            display: block;
            & + img{
                margin-top: 32px;
                margin-bottom: 8px;
            }
        }
        &.got {
            width: 574px;
            height: 558px;
            background-image: url(https://img.chaolu.com.cn/ACT/get-mini-voucher/jinping/8.png);
            padding: 196px 0 0;
            .t1 {
                color: #5A0A00;
            }

            .button {
                margin: 144px 0 0;
            }
            &.noNew{
                padding-top: 170px;
                .t1{
                    text-align: center;
                    padding: 0 60px;
                    font-size: 4vw;
                    line-height: 8vw
                }
                .button {
                    margin: 104px 0 0;
                }
            }
        }

        .button {
            width: 420px;
            height: 96px;
            background: linear-gradient(90deg, #FFCF4F 0%, #FFB21C 100%);
            margin: 24px 0 0;
            color: #242831;
            border-radius: 12px;
        }
    }

    .result-close {
        width: 60px;
        height: 60px;
        margin: 48px auto 0;
        display: block;
    }
}</style>
